<template>
	<div>
		<v-card dense style="margin-top: -30px;">
			<v-card-text>
				<v-row>
					<v-col
						class="s-col-form"
					>
						<v-checkbox
							v-model="caliberCheck"
							label="Calibre"
							hide-details=""
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						v-if="caliberCheck"
						class="s-col-form"
					>
						<s-select-definition
							:dgr="groupID"
							:def="1326"
							label="Calibre"
							v-model="itemSave.TypeCaliber"
						>
						</s-select-definition>
					</v-col>
					<v-col
						v-if="typeCultiveSelect == 1"
						class="s-col-form"
					>
						<s-text
							label="V. Mínimo"
							v-model="itemSave.PiwMin"
						></s-text>
					</v-col>
					<v-col
						v-if="typeCultiveSelect == 1"
						class="s-col-form"
					>
						<s-select-definition
							:def="1148"
							label="Operador Lógico"
							v-model="itemSave.PiwMin"
						></s-select-definition>
					</v-col>
					<v-col
						v-if="typeCultiveSelect == 1"
						class="s-col-form"
					>
						<s-text
							label="V. Máximo"
						></s-text>
					</v-col>
					<v-col
						v-if="typeCultiveSelect == 1"
						class="s-col-form"
					>
						<s-select-definition
							label="Categoría"
							:def="1438"
						></s-select-definition>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						v-if="typeCultiveSelect == 3"
						class="s-col-form"
					>
						<s-text
							label="Cantidad"
						></s-text>
					</v-col>
					<v-col
						v-if="typeCultiveSelect == 3"
						class="s-col-form"
					>
						<s-text
							label="Porcentaje"
						></s-text>
					</v-col>
					<v-col
						class="s-col-form"
					>
						<s-select-definition
							label="Uni. Medida"
							:def="1436"
						></s-select-definition>
					</v-col>
					<v-col
						class="s-col-form"
					>
						<s-select-definition
							label="Uni. Tiempo"
							:def="1437"
						></s-select-definition>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn
							fab
							color="success"
							x-small
							@click="addPieceWork()"
						>
							<i class="fas fa-plus"></i>
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-data-table
							dense
							:headers="headers"
						></v-data-table>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import _sDefinition from "@/services/General/DefinitionService.js";

	export default {
		props: {
			rowSelect: {},
		},

		data() {
			return {
				itemSave : {},
				caliberCheck: false,
				typeCultiveSelect : 0,
				listCalibers : [],
				groupID: 0,
				headers : [
					{text: "Calibre", value: "minimo"},
					{text: "Mínimo", value: "minimo"},
					{text: "Operador", value: "minimo"},
					{text: "Máximo", value: "minimo"},
					{text: "Categoría", value: "minimo"},
					{text: "Unidades", value: "minimo"},
				]
			}
		},

		methods: {

			Initialice()
			{
				this.typeCultiveSelect = this.rowSelect.TypeCultive;
				this.groupID = parseInt(this.rowSelect.TypeCultiveFormat);
			},

			addPieceWork()
			{

			}
		},

		created () {
			this.Initialice();
		},
	}
</script>

<style scoped>

</style>