<template>
	<div>
		<v-card dense>
			<v-stepper
				non-linear
			>
				<v-stepper-header>
					<v-stepper-step
						editable
						step="1"
					>
						Destajo
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step
						editable
						step="2"
					>
						Avance
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step
						step="3"
						editable
					>
						Rendimiento
					</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step
						step="4"
						editable
					>
						Asistencia
					</v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content
						step="1"
					>
						<piece-work
							:rowSelect="rowSelect"
						></piece-work>
					</v-stepper-content>
					<v-stepper-content
						step="2"
					>
						<advance
							:rowSelect="rowSelect"
						></advance>
					</v-stepper-content>
					<v-stepper-content
						step="3"
					>
						<performance
							:rowSelect="rowSelect"
						></performance>
					</v-stepper-content>
					<v-stepper-content
						step="4"
					>
						<v-card>
							{{ "step1" }}
						</v-card>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card>
	</div>
</template>

<script>
	import Advance from './Advance.vue';
	import Performance from './Performance.vue';
	import PieceWork from './PieceWork.vue';

	export default {
		components: { 
			PieceWork,
			Advance,
			Performance 
		},

		props: {
			rowSelect: {},
		},

		created () {
			console.log('selecc ', this.rowSelect);;
		},
		
	}
</script>
