<template>
	<v-card dense>
		<v-card-text>
			<v-row>
				<v-col  
					v-if="typeCultiveSelect == 3"
					class="s-col-form"
				>
					<s-select-definition
						:def="1429"
						label="Presentación"
					></s-select-definition>
				</v-col>
				<v-col  class="s-col-form">
					<s-text
						label="Valor Mínimo"
						decimal
					></s-text>
				</v-col>
				<v-col  class="s-col-form">
					<s-select-definition
						:def="1148"
						label="Operador Lógico"
					></s-select-definition>
				</v-col>
				<v-col  class="s-col-form">
					<s-text
						label="Valor Máximo"
						decimal
					></s-text>
				</v-col>
				<v-col  class="s-col-form">
					<s-text
						decimal
						label="Precio"
					></s-text>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		props: {
			rowSelect: {},
		},

		data() {
			return {
				typeCultiveSelect : 0,
			}
		},

		created () {
			this.typeCultiveSelect = this.rowSelect.TypeCultive;
		},
	}
</script>

<style scoped>

</style>